
<template>
    <div>
        <div  class="cell-bg">
            <van-row>
                <van-col span="7"><p class="type-label">{{dictValue}}</p></van-col>
                <van-col span="7"><p class="name-label">{{info.name|ellipsis}}</p></van-col>
                <van-col span="10">
                    <p class="bankNo-label">{{info.phoneNo}}
                        <van-icon name="arrow" size="0.5rem" class="arrow"/>
                    </p>
                </van-col>
            </van-row>
        </div>
        <div  class="line">
        </div>
    </div>
</template>

<script>
    export default {
        name: "myCustomerCell",
        props:['info','customerGroup'],
        filters: {
            ellipsis (value) {
                if (!value) return ''
                if (value.length > 5) {
                    return value.slice(0,5) + '...'
                }
                return value
            }
        },
        data(){
            return {
                dictValue:''
            }
        },
        mounted() {
            this.toGetDictValue(this.info.customerGroup)
        },
        methods:{
            toGetDictValue(dictKey){
                for (var item of this.customerGroup){
                    if (dictKey == item.dictKey){
                        this.dictValue = item.dictValue;
                    }
                }
            },
    }
    }
</script>

<style lang="less" scoped>
    .bg{
        .time{
            height: 1rem;
            line-height: 1rem;
            color: black;
            background: #dddddd;
        }
        .info {
            height: 1.3rem;
            background: white;
            line-height: 1.3rem;
        }
        .cell-bg{
            margin: 0 0.2rem
        }
        .type-label{
            text-align: center;
            width: 1.2rem;
            height:0.5rem;
            background-color: #3F7C53;
            line-height: 0.5rem;
            margin-top: 0.4rem;
            border-radius: 0.05rem;
            color: white
        }
        .name-label{
            text-align: left
        }
        .bankNo-label{
            text-align: left
        }
        .arrow{
            float: right;
            margin-top: 0.4rem;
            margin-left: 10px
        }
        .line{
            height: 1px;
            background-color: #dddddd;
            margin-top: -1px;
            margin-left: 0.2rem;
            margin-right: 0.2rem
        }
    }
</style>

